const terrazzo = {
  colorBase: [
    {
      'productNumber': 'BM01',
      'productName': 'Natural',
      'productHex': 'D7D5C9'
    },
    {
      'productNumber': 'BM02',
      'productName': 'White Light',
      'productHex': 'DDDCD3'
    },
    {
      'productNumber': 'BM03',
      'productName': 'White Mid',
      'productHex': 'E0E0D9'
    },
    {
      'productNumber': 'BM04',
      'productName': 'White Strong',
      'productHex': 'E1E2DC'
    },
    {
      'productNumber': 'BM05',
      'productName': 'Caffe Latte Intense',
      'productHex': '958065'
    },
    {
      'productNumber': 'BM06',
      'productName': 'Caffe Latte Strong',
      'productHex': 'AE9D88'
    },
    {
      'productNumber': 'BM07',
      'productName': 'Caffe Latte Mid',
      'productHex': 'A99885'
    },
    {
      'productNumber': 'BM08',
      'productName': 'Caffe Latte Light',
      'productHex': 'ACA08A'
    },
  ],
  colorStone: [
    {
      'productNumber': 'BM09',
      'productName': 'Imperial Intense',
      'productHex': 'E4BE77'
    },
    {
      'productNumber': 'BM10',
      'productName': 'Imperial Strong',
      'productHex': 'E0C37E'
    },
    {
      'productNumber': 'BM11',
      'productName': 'Imperial Mid',
      'productHex': 'DABE7D'
    },
    {
      'productNumber': 'BM12',
      'productName': 'Imperial Light',
      'productHex': 'D7CA9B'
    },
    {
      'productNumber': 'BM13',
      'productName': 'Brick Intense',
      'productHex': '914D40'
    },
    {
      'productNumber': 'BM14',
      'productName': 'Brick Strong',
      'productHex': '97594F'
    },
    {
      'productNumber': 'BM15',
      'productName': 'Brick Mid',
      'productHex': 'A26056'
    },
    {
      'productNumber': 'BM16',
      'productName': 'Brick Light',
      'productHex': 'B67D72'
    },
    {
      'productNumber': 'BM17',
      'productName': 'Cacao Intense',
      'productHex': '675648'
    },
    {
      'productNumber': 'BM18',
      'productName': 'Cacao Strong',
      'productHex': '8D7B6B'
    },
    {
      'productNumber': 'BM19',
      'productName': 'Cacao Mid',
      'productHex': 'CAB9A8'
    },
    {
      'productNumber': 'BM20',
      'productName': 'Cacao Light',
      'productHex': 'CBC0B2'
    },
    {
      'productNumber': 'BM21',
      'productName': 'Sand Intense',
      'productHex': 'A4886D'
    },
    {
      'productNumber': 'BM22',
      'productName': 'Sand Strong',
      'productHex': 'B59E87'
    },
    {
      'productNumber': 'BM23',
      'productName': 'Sand Mid',
      'productHex': 'D5C7B2'
    },
    {
      'productNumber': 'BM24',
      'productName': 'Sand Light',
      'productHex': 'D7CEBD'
    },
    {
      'productNumber': 'BM25',
      'productName': 'Shitake Intense',
      'productHex': '9E816D'
    },
    {
      'productNumber': 'BM26',
      'productName': 'Shitake Strong',
      'productHex': 'A9917E'
    },
    {
      'productNumber': 'BM27',
      'productName': 'Shitake Mid',
      'productHex': 'D3C0AD'
    },
    {
      'productNumber': 'BM28',
      'productName': 'Shitake Light',
      'productHex': 'D8CDBD'
    },
    {
      'productNumber': 'BM29',
      'productName': 'Camel Intense',
      'productHex': 'AB8654'
    },
    {
      'productNumber': 'BM30',
      'productName': 'Camel Strong',
      'productHex': 'B7966D'
    },
    {
      'productNumber': 'BM31',
      'productName': 'Camel Mid',
      'productHex': 'DCCFB5'
    },
    {
      'productNumber': 'BM32',
      'productName': 'Camel Light',
      'productHex': 'E3DAC3'
    },
    {
      'productNumber': 'BM33',
      'productName': 'Cuberdon Intense',
      'productHex': '87605C'
    }
  ]
}

export default terrazzo;
import copy from './copy';
import mortex from './mortex';
import terrazzo from './terrazzo';

const exports = {
  copy,
  mortex,
  terrazzo,
}

export default exports;
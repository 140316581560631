const verifyInput = (product, dimension, baseColor, stoneColors) => {
  const errorObj = {};
  if (
    (typeof product !== 'undefined' && dimension !== 0) &&
    (typeof baseColor !== 'undefined' && baseColor.hasOwnProperty('productName')) &&
    ((product === 'Terrazzo' && stoneColors.length > 0) || product === 'Mortex')
  ) {
    return {}
  }

  if (dimension === 0) {
    Object.assign(errorObj, { noDimensionSet: true })
  }
  if (typeof baseColor === 'undefined') {
    Object.assign(errorObj, { noBaseColorSet: true })
  }
  if (product === 'Terrazzo' && stoneColors.length === 0) {
    Object.assign(errorObj, { noStoneColorSet: true })
  }
  return errorObj;
}

export default verifyInput;

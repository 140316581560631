import resources from '../resources';

const getProductName =  (product, baseColor, stoneColors) => {
  const mapping = resources.copy.calculator.quote.productName;
  const stoneColorsLength = stoneColors.length;
  if (product === 'Terrazzo') {
    return `${baseColor.productName} with ${mapping[stoneColorsLength]} colour${stoneColorsLength > 1
      ? 's'
      : ''}`
  } else if (product === 'Mortex') {
    return `${baseColor.productName} (${baseColor.productNumber})`
  }
  return ''
}

export default getProductName
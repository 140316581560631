const copy = {
  header: {
    title: [
      'forma',
      'surfaces'
    ],
    menu: [
      {
        title: 'Home',
        to: '/',
        id: 'home',
      },
      {
        title: 'Vision',
        to: '/',
        id: 'vision'
      },
      {
        title: 'Gallery',
        to: '/gallery',
      },
      {
        title: 'Contact',
        to: '/contact',
      }
      // , {
      //   title: 'Terrazzo',
      //   to: '/terrazzo',
      //   id: 'Terrazzo'
      // }, {
      //   title: 'Macrocimento',
      //   to: '/macrocimento',
      //   id: 'macrocimento'
      // }, {
      //   title: 'Email',
      //   to: '/email',
      //   id: 'email'
      // }
    ]
  },
  panel: {
    heading: 'Design your own floor',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.'
  },
  calculator: {
    productHeading: 'Price Calculator',
    productSelect: [
      'Mortex',
      'Terrazzo'
    ],
    productNaming: [
      'Mortex',
      'Beal Stone Terrazzo'
    ],
    colorHeading: 'Colour',
    stoneHeading: 'Stone Colours',
    dimensionHeading: 'Dimensions in square meter',
    button: 'Calculator your floor',
    quote: {
      productName: [
        '',
        'one',
        'two',
        'tree',
        'four',
        'five',
        'six',
        'seven',
        'eight',
      ]
    }
  },
  who: {
    title: 'Who we are',
    subtitle: 'We\'re crazy about innovations and improving life with technology & design',
  },
  what1: {
    title: 'What we do',
    subtitle: 'Mortex',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.',
    learnMore: '/mortex'
  },
  what2: {
    title: 'What we do',
    subtitle: 'Terrazzo',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.',
    learnMore: '/terrazzo'
  },
  work: {
    title: 'Our work',
    subtitle: 'Showcase of our best work'
  },
  socialUrls: [
    'http://facebook.com/forma',
    'http://twitter.com/forma',
    'http://pinterest.com/forma'
  ],
  footer: {
    title: [
      'forma',
      'surfaces'
    ],
  },
  email: {
    title: 'Enter your contact details:',
    description: 'So we can email you a price confirmation and a mockup of your mural',
    name: 'First Name',
    email: 'Email',
  },
  results: {
    colors: [
      '0',
      '1',
      '2',
      '3'
    ],
    subtitle: 'Composition',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.',
    button: 'Email me quote',
    reset: 'Reset filter',
    failure: {
      heading: 'Failure',
      subHeading: 'Something went wrong, please try again later'
    },
    success: {
      heading: 'Success',
      subHeading: 'We have sent your quote to your email address',
    }
  },
  productPage: {
    Terrazzo: {
      title: 'Terrazzo is next big thing in architecture',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor...',
      subtitle: 'Our Vision',
      statement: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor.,',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor.,',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor.',
      ]
    },
    Mortex: {
      title: 'Mortex is next big thing in architecture',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor...',
      subtitle: 'Our Vision',
      statement: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor.,',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor.,',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eleifend rhoncus semper. In quam sapien, iaculis eget metus et, iaculis elementum est. Sed non massa nisl. Aliquam vestibulum non est vel lacinia. Donec id ex id felis blandit elementum tristique a orci. Sed sed est pharetra velit tempus hendrerit et non massa. Fusce a ultrices mauris. Nulla porttitor tempus elementum. Mauris tincidunt bibendum finibus. In aliquet facilisis turpis in auctor.',
      ]
    }
  },
  showcasePage: [
    {
      title: 'SHOWCASE PAGE 1',
      description: 'SHOW CASE DESCRIPTION',
      subtitle: 'SHOW CASE SUBTITLE',
      statement: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.'
    },
    {
      title: 'SHOWCASE PAGE2 ',
      description: 'SHOW CASE DESCRIPTION',
      subtitle: 'SHOW CASE SUBTITLE',
      statement: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.'
    },
    {
      title: 'SHOWCASE PAGE 3',
      description: 'SHOW CASE DESCRIPTION',
      subtitle: 'SHOW CASE SUBTITLE',
      statement: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.'
    },
    {
      title: 'SHOWCASE PAGE 4',
      description: 'SHOW CASE DESCRIPTION',
      subtitle: 'SHOW CASE SUBTITLE',
      statement: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nec pretium eros, vel consequat nisl. Nunc accumsan, risus ut vestibulum semper, dolor turpis rutrum nibh, a vulputate augue erat eu nulla. Nulla ullamcorper ante vitae diam iaculis feugiat et a nulla. Nam id urna fermentum, rutrum dui a, cursus felis.'
    },
  ],
  contactPage: {
    heading: 'Contact Form',
    name: 'First and Surname',
    email: 'Email address',
    phone: 'Phone number',
    subject: 'Subject',
    message: 'Message',
    button: 'Send now',
    info: [
      {
        heading: 'Visit Us',
        description: '417 Wick lane Suite 308<br/>E3 2PX London<br/>UK',
      },
      {
        heading: 'Working time',
        description: 'Monday - Friday<br/>8:30 AM to 9:00 PM',
      },
      {
        heading: 'Email us',
        description: 'E-mail: <a href="mailto:info@combinedca.com">info@combinedca.com</a>',
      },
      {
        heading: 'Call us',
        description: '0721 24 85 13',
      }
    ]
  },
  error: {
    dimensionSet: 'This is a required field',
    stoneColorSet: 'Please select up to 3 colors',
    baseColorSet: 'Please select one color to continue'
  }
}

export default copy;
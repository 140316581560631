const mortex = [
  {
    'productNumber': 'BM01',
    'productName': 'Natural',
    'productHex': 'D7D5C9'
  },
  {
    'productNumber': 'BM02',
    'productName': 'White Light',
    'productHex': 'DDDCD3'
  },
  {
    'productNumber': 'BM03',
    'productName': 'White Mid',
    'productHex': 'E0E0D9'
  },
  {
    'productNumber': 'BM04',
    'productName': 'White Strong',
    'productHex': 'E1E2DC'
  },
  {
    'productNumber': 'BM05',
    'productName': 'Caffe Latte Intense',
    'productHex': '958065'
  },
  {
    'productNumber': 'BM06',
    'productName': 'Caffe Latte Strong',
    'productHex': 'AE9D88'
  },
  {
    'productNumber': 'BM07',
    'productName': 'Caffe Latte Mid',
    'productHex': 'A99885'
  },
  {
    'productNumber': 'BM08',
    'productName': 'Caffe Latte Light',
    'productHex': 'ACA08A'
  },
  {
    'productNumber': 'BM09',
    'productName': 'Imperial Intense',
    'productHex': 'E4BE77'
  },
  {
    'productNumber': 'BM10',
    'productName': 'Imperial Strong',
    'productHex': 'E0C37E'
  },
  {
    'productNumber': 'BM11',
    'productName': 'Imperial Mid',
    'productHex': 'DABE7D'
  },
  {
    'productNumber': 'BM12',
    'productName': 'Imperial Light',
    'productHex': 'D7CA9B'
  },
  {
    'productNumber': 'BM13',
    'productName': 'Brick Intense',
    'productHex': '914D40'
  },
  {
    'productNumber': 'BM14',
    'productName': 'Brick Strong',
    'productHex': '97594F'
  },
  {
    'productNumber': 'BM15',
    'productName': 'Brick Mid',
    'productHex': 'A26056'
  },
  {
    'productNumber': 'BM16',
    'productName': 'Brick Light',
    'productHex': 'B67D72'
  },
  {
    'productNumber': 'BM17',
    'productName': 'Cacao Intense',
    'productHex': '675648'
  },
  {
    'productNumber': 'BM18',
    'productName': 'Cacao Strong',
    'productHex': '8D7B6B'
  },
  {
    'productNumber': 'BM19',
    'productName': 'Cacao Mid',
    'productHex': 'CAB9A8'
  },
  {
    'productNumber': 'BM20',
    'productName': 'Cacao Light',
    'productHex': 'CBC0B2'
  },
  {
    'productNumber': 'BM21',
    'productName': 'Sand Intense',
    'productHex': 'A4886D'
  },
  {
    'productNumber': 'BM22',
    'productName': 'Sand Strong',
    'productHex': 'B59E87'
  },
  {
    'productNumber': 'BM23',
    'productName': 'Sand Mid',
    'productHex': 'D5C7B2'
  },
  {
    'productNumber': 'BM24',
    'productName': 'Sand Light',
    'productHex': 'D7CEBD'
  },
  {
    'productNumber': 'BM25',
    'productName': 'Shitake Intense',
    'productHex': '9E816D'
  },
  {
    'productNumber': 'BM26',
    'productName': 'Shitake Strong',
    'productHex': 'A9917E'
  },
  {
    'productNumber': 'BM27',
    'productName': 'Shitake Mid',
    'productHex': 'D3C0AD'
  },
  {
    'productNumber': 'BM28',
    'productName': 'Shitake Light',
    'productHex': 'D8CDBD'
  },
  {
    'productNumber': 'BM29',
    'productName': 'Camel Intense',
    'productHex': 'AB8654'
  },
  {
    'productNumber': 'BM30',
    'productName': 'Camel Strong',
    'productHex': 'B7966D'
  },
  {
    'productNumber': 'BM31',
    'productName': 'Camel Mid',
    'productHex': 'DCCFB5'
  },
  {
    'productNumber': 'BM32',
    'productName': 'Camel Light',
    'productHex': 'E3DAC3'
  },
  {
    'productNumber': 'BM33',
    'productName': 'Cuberdon Intense',
    'productHex': '87605C'
  },
  {
    'productNumber': 'BM34',
    'productName': 'Cuberdon Strong',
    'productHex': '8D6662'
  },
  {
    'productNumber': 'BM35',
    'productName': 'Cuberdon Mid',
    'productHex': '9F7F7A'
  },
  {
    'productNumber': 'BM36',
    'productName': 'Cuberdon Light',
    'productHex': 'AC9692'
  },
  {
    'productNumber': 'BM37',
    'productName': 'Vesuvio Intense',
    'productHex': '9F5132'
  },
  {
    'productNumber': 'BM38',
    'productName': 'Vesuvio Strong',
    'productHex': 'B96E4E'
  },
  {
    'productNumber': 'BM39',
    'productName': 'Vesuvio Mid',
    'productHex': 'C08066'
  },
  {
    'productNumber': 'BM40',
    'productName': 'Vesuvio Light',
    'productHex': 'C8947C'
  },
  {
    'productNumber': 'BM41',
    'productName': 'Olive Intense',
    'productHex': '9D9D66'
  },
  {
    'productNumber': 'BM42',
    'productName': 'Olive Strong',
    'productHex': 'A5A46E'
  },
  {
    'productNumber': 'BM43',
    'productName': 'Olive Mid',
    'productHex': '9FA17B'
  },
  {
    'productNumber': 'BM44',
    'productName': 'Olive Light',
    'productHex': 'ACAD89'
  },
  {
    'productNumber': 'BM45',
    'productName': 'Pinctada Intense',
    'productHex': '5A5B57'
  },
  {
    'productNumber': 'BM46',
    'productName': 'Pinctada Strong',
    'productHex': '7E7B77'
  },
  {
    'productNumber': 'BM47',
    'productName': 'Pinctada Mid',
    'productHex': '858279'
  },
  {
    'productNumber': 'BM48',
    'productName': 'Pinctada Light',
    'productHex': 'A29F9A'
  },
  {
    'productNumber': 'BM49',
    'productName': 'Welsh Intense',
    'productHex': '6B755D'
  },
  {
    'productNumber': 'BM50',
    'productName': 'Welsh Strong',
    'productHex': '7A8F64'
  },
  {
    'productNumber': 'BM51',
    'productName': 'Welsh Mid',
    'productHex': '86A26F'
  },
  {
    'productNumber': 'BM52',
    'productName': 'Welsh Light',
    'productHex': 'A3B58A'
  },
  {
    'productNumber': 'BM53',
    'productName': 'Agua Intense',
    'productHex': '41C2DB'
  },
  {
    'productNumber': 'BM54',
    'productName': 'Agua Strong',
    'productHex': '79C6DA'
  },
  {
    'productNumber': 'BM55',
    'productName': 'Agua Mid',
    'productHex': '7DB7C4'
  },
  {
    'productNumber': 'BM56',
    'productName': 'Agua Light',
    'productHex': 'BCD2CF'
  },
  {
    'productNumber': 'BM57',
    'productName': 'Makala Intense',
    'productHex': '444444'
  },
  {
    'productNumber': 'BM58',
    'productName': 'Makala Strong',
    'productHex': '615C59'
  },
  {
    'productNumber': 'BM59',
    'productName': 'Makala Mid',
    'productHex': '6D6E69'
  },
  {
    'productNumber': 'BM60',
    'productName': 'Makala Light',
    'productHex': '96938E'
  },
  {
    'productNumber': 'BM61',
    'productName': 'Oltra Marine Intense',
    'productHex': '6076AD'
  },
  {
    'productNumber': 'BM62',
    'productName': 'Oltra Marine Strong',
    'productHex': '6F85B2'
  },
  {
    'productNumber': 'BM63',
    'productName': 'Oltra Marine Mid',
    'productHex': '6E81AC'
  },
  {
    'productNumber': 'BM64',
    'productName': 'Oltra Marine Light',
    'productHex': '7B8AAB'
  }
]

export default mortex;
import React, {useState} from 'react';
import styled from 'styled-components';
import {SocialIcon} from 'react-social-icons';
import {AnchorLink as Link} from "gatsby-plugin-anchor-links";
import Navbar from 'react-bootstrap/Navbar';

import resources from '../../resources';
import {pixels} from '../../config/breakpoints';

const socialIcons = resources.copy.socialUrls;
const {header} = resources.copy;

const MenuTitle = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuTitleName = styled.span`
  font-size: 30px;
  color: #212121;
  letter-spacing: 8px;
  line-height: 30px;
`

const MenuTitleSubName = styled.span`
  /* Surfaces: */
  font-size: 9px;
  color: #3A405B;
  letter-spacing: 13.5px;
`

const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
  padding: 20px 0;
  @media (min-width: ${pixels.tablet}) {
    margin-left: 20px;
    padding: 0;
  }
`
const MenuRest = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  
  @media (min-width: ${pixels.tablet}) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
`
const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  
  @media (min-width: ${pixels.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
  }
`

const MenuLink = styled(Link)`
  text-decoration: none;
  color: #464855;
  letter-spacing: 0.58px;
  font-family: Lato;
  font-weight: 700;

  &:hover, &:active, &:focus {
    font-weight: 700;
    color: #020202;
    text-decoration: none;
  }
  margin: 10px 0;
  @media (min-width: ${pixels.tablet}) {
    margin: 0 10px;
  }
`

const Menu = () => {
  const [isNavCollapsed, setNavCollapsed] = useState(false);
  const handleNavCollapse = () => {
    setNavCollapsed(!isNavCollapsed);
  }

  const closeNav = () => {
    setNavCollapsed(false)
  }

  return (
    <Navbar bg="light" expand="md" id="menu" sticky="top" expanded={isNavCollapsed}>
      <Navbar.Brand href="/#home">
        <MenuTitle>
          <MenuTitleName>{header.title[0].toUpperCase()}</MenuTitleName>
          <MenuTitleSubName>{header.title[1].toUpperCase()}</MenuTitleSubName>
        </MenuTitle>
      </Navbar.Brand>
      <Navbar.Toggle
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
      />
      <Navbar.Collapse
          id={"basic-navbar-nav"}
          className={`justify-content-end`}
      >
        <MenuRest>
          <MenuContent>
            {header.menu.map((element) => (<MenuLink
                to={`${element.to}${element.id
                  ? `#${element.id}`
                  : ''}`}
                key={`link-${element.title}`}
                onAnchorLinkClick={closeNav}
                activeStyle={{textDecoration: 'none', border: 'red'}}
              >
                {element.title.toUpperCase()}
              </MenuLink>)
            )
            }
          </MenuContent>
          <SocialIconsWrapper>
            {socialIcons.map((e, i) => <SocialIcon
                key={`social-${i}`}
                style={{height: 25, width: 25, marginRight: 5}}
                bgColor={'#464855'} url={e}
              />
            )
            }
          </SocialIconsWrapper>
        </MenuRest>
      </Navbar.Collapse>
    </Navbar>
  )

}

export default Menu;

import { terrazzoPrice, mortexPrice } from '../config';

const format = (amount) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const calculatePrice =  (dimension, product) => {
  const amount = (product === 'Terrazzo')
    ? dimension * terrazzoPrice
    : dimension * mortexPrice;
  return format(amount)
}

export default calculatePrice

const size = {
  mobile: '425',
  tablet: '768',
  desktop: '1024',
}

const pixels = {
  mobile: '425px',
  tablet: '768px',
  desktop: '1024px',
}

export {
  size,
  pixels,
};
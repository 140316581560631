import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {SocialIcon} from 'react-social-icons';
import {Link} from 'gatsby'
import resources from '../../resources';
import {size, pixels} from '../../config/breakpoints';
import {getWidth} from '../../utils';

const {footer} = resources.copy;
const socialIcons = resources.copy.socialUrls;

const FooterWrapper = styled.div`

  display: flex;
  background-color: black;
  color: white;
  padding: 20px 50px;
  flex-direction: column;
`

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${pixels.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const FooterTitle = styled.div`
  display: flex;
  flex-direction: column;
`

const FooterTitleName = styled.span`
  font-size: 30px;
`

const FooterTitleSubName = styled.span`
  font-size: 9px;
  letter-spacing: 9.5px;
  margin-bottom: 31px;
`

const Product = styled.div`
  order: ${({order}) => order};
`
const Heading = styled.div`
  font-size: 24px;
  margin: 33px 0;

  @media(min-width: ${pixels.tablet}) {
    margin-top: -60px;
    margin-bottom: 45px;
  }
`
const Item = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 45px;
  justify-content: flex-start;

  @media(min-width: ${pixels.tablet}) {
    margin-top: -55px;
    margin-bottom: 45px;
    margin-left: 20px;
    justify-content: space-between;

  }
`
const CopyRight = styled.div`
  /* Bg: */
  background: #151A1F;
  /* © 2018. All rights r: */
  font-size: 14px;
  color: #D5D5D5;
  padding: 28px 50px;
`
const Footer = () => {
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth())
    };
    // set resize listener
    if(typeof window !== 'undefined') {
      window.addEventListener('resize', resizeListener);
    }
  })

  return (
    <>
      <FooterWrapper>
        <FooterTitle>
          <FooterTitleName>{footer.title[0].toUpperCase()}</FooterTitleName>
          <FooterTitleSubName>{footer.title[1].toUpperCase()}</FooterTitleSubName>
        </FooterTitle>
        <FooterContent>
          <Product order={width > size.tablet ? 1 : 4}>
            <Item>
              417 Wick lane Suite 308<br/>
              E3 2PX London<br/>
              UK<br/>
              <br/>
              Phone: 714-599-8168<br/>
              E-mail: <a style={{color: 'white'}} href="mailto:info@combinedca.com">info@combinedca.com</a>
            </Item>
          </Product>
          <Product order={width > size.tablet ? 2 : 1}>
            <Heading>
              Product
            </Heading>
            <Item>
              <Link style={{textDecoration: 'none', color: 'white'}} to={'/terrazzo'}>
                Terrazzo
              </Link>
            </Item>
            <Item>
              <Link style={{textDecoration: 'none', color: 'white'}} to={'/mortex'}>
                Mortex
              </Link>
            </Item>
          </Product>
          <Product order={width > size.tablet ? 3 : 2}>
            <Heading>
              About Us
            </Heading>
            <Item>
              <Link style={{textDecoration: 'none', color: 'white'}} to={'/#vision'}>
                Vision
              </Link>
            </Item>
            <Item>
              <Link style={{textDecoration: 'none', color: 'white'}} to={'/gallery'}>
                Gallery
              </Link>
            </Item>
            <Item>
              <Link style={{textDecoration: 'none', color: 'white'}} to={'/contact'}>
                Contact Us
              </Link>
            </Item>
          </Product>
          <Product order={width > size.tablet ? 4 : 3}>
          <SocialIconsWrapper>
            {socialIcons.map((e, i) => <SocialIcon bgColor={'grey'} key={`social-${i}`}
                                                   style={{height: 25, width: 25, marginRight: 5}} url={e}/>
            )
            }
          </SocialIconsWrapper>
          </Product>
        </FooterContent>
      </FooterWrapper>
      <CopyRight>© 2018. All rights reserved. FORMA SURFACES</CopyRight>
    </>
  )
}


export default Footer;